import { Cloud } from '@readcloud/data';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  AddConnectedCloudThunkAction,
  DeleteConnectedCloudThunkAction,
  GetAllCloudsThunkAction,
  GetConnectedCloudsThunkAction,
  GetInstitutionCloudsThunkAction,
  ListCloudForUserThunkAction,
  SearchCloudsThunkAction,
  UpdateConnectedCloudThunkAction,
} from './thunk';
import { CloudsState } from './types';

const initialState: CloudsState = {
  clouds: {},
  institutionClouds: {},
  searchCloudsResult: {
    result: {
      items: [],
      moreAvailable: false,
      nextOffset: 0,
      timeMs: 0,
    },
  },
  listCloudForUser: [],
};

const name = 'clouds';

const asyncActions = {
  addCloud: createAsyncThunk(`${name}/addCloud`, AddConnectedCloudThunkAction),
  getClouds: createAsyncThunk(
    `${name}/getClouds`,
    GetConnectedCloudsThunkAction
  ),
  getInstitutionClouds: createAsyncThunk(
    `${name}/getInstitutionClouds`,
    GetAllCloudsThunkAction
  ),
  deleteCloud: createAsyncThunk(
    `${name}/deleteCloud`,
    DeleteConnectedCloudThunkAction
  ),
  updateCloud: createAsyncThunk(
    `${name}/updateCloud`,
    UpdateConnectedCloudThunkAction
  ),
  searchClouds: createAsyncThunk(
    `${name}/searchClouds`,
    SearchCloudsThunkAction
  ),
  listCloudsForUser: createAsyncThunk(
    `${name}/listCloudsForUser`,
    ListCloudForUserThunkAction
  ),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    addClouds(state, action: PayloadAction<Cloud[]>) {
      const tempClouds = {};
      action.payload?.forEach((cloud: Cloud) => {
        tempClouds[cloud.id] = cloud;
      });
      state = { ...state, clouds: { ...state.clouds, ...tempClouds } };
    },
    deltaClouds(state, action: PayloadAction<Cloud[]>) {
      //keep track of cloud we're updating.
      const updatedCloudIds = [];

      action.payload.forEach((newUpdatedCloud) => {
        //find and replace.
        const index = Object.values(state.clouds).findIndex(
          (cloud) => cloud.id === newUpdatedCloud.id
        );
        if (index >= 0) {
          if (!newUpdatedCloud.deleted) {
            //replace cloud
            state.clouds[index] = newUpdatedCloud;
          } else {
            //delete cloud
            Object.values(state.clouds).splice(index, 1);
          }
          updatedCloudIds.push(newUpdatedCloud.id);
        }
      });

      //filter the annos we have already updated.
      const newArrClouds = action.payload.filter(
        (cloud) => !updatedCloudIds.includes(cloud.id) && !cloud.deleted
      );

      const newClouds = {};

      newArrClouds.forEach((cloud: Cloud) => {
        newClouds[cloud.id] = cloud;
      });

      //add the rest to state.
      state.clouds = { ...newClouds, ...state.clouds };
    },
    setClouds(state, action: PayloadAction<Cloud[]>) {
      const tempClouds = {};
      action.payload?.forEach((cloud: Cloud) => {
        tempClouds[cloud.id] = cloud;
      });

      state.clouds = tempClouds;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncActions.addCloud.fulfilled, (state, action) => {
      //TODO: This is very very questionable. Creating a cloud should only require the input of a few fields, but the logic around adding one to state after creation is expecting everything to be there.
      state.clouds[action.payload.id] = {
        archivedFor: [],
        axcelerateId: '',
        deleted: false,
        customBookSortOrder: false,
        persistent: true,
        type: 'User',
        created: new Date().toISOString(),
        updated: new Date().toISOString(),
        extConfig: {},
        institution: '',
        institutions: [],
        resellers: [],
        subject: '',
        yearLevel: '',
        ...action.payload,
      };
    });
    builder.addCase(asyncActions.getClouds.fulfilled, (state, action) => {
      const tempClouds = {};
      action.payload?.clouds.forEach((cloud: Cloud) => {
        tempClouds[cloud.id] = cloud;
      });
      state.clouds = tempClouds;
      state.permissions = action.payload?.permissions;
    });
    builder.addCase(
      asyncActions.getInstitutionClouds.fulfilled,
      (state, action) => {
        const tempClouds = {};
        action.payload?.schoolClouds.forEach((clouds: any) => {
          tempClouds[clouds.id] = clouds;
        });
        state.institutionClouds = tempClouds;
      }
    );
    builder.addCase(asyncActions.deleteCloud.fulfilled, (state, action) => {
      delete state.clouds[action.payload];
    });
    builder.addCase(asyncActions.updateCloud.fulfilled, (state, action) => {
      state.clouds[action.payload.id] = {
        ...state.clouds[action.payload.id],
        ...action.payload,
      };

      if (state.searchCloudsResult?.result?.items) {
        const filtered = state.searchCloudsResult.result.items.filter(
          (item) => item.cloud.id !== action.payload?.id
        );
        state.searchCloudsResult.result.items = filtered;
      }
    });
    builder.addCase(asyncActions.searchClouds.fulfilled, (state, action) => {
      const {
        result: { items },
        offset,
      } = action.payload;

      state.searchCloudsResult = {
        result: {
          ...action.payload.result,
          items:
            offset === 0
              ? items
              : [...state.searchCloudsResult.result.items, ...items],
        },
      };
    });
    builder.addCase(
      asyncActions.listCloudsForUser.fulfilled,
      (state, action) => {
        state.listCloudForUser = action.payload;
      }
    );
  },
});

const { actions, reducer } = slice;

export const cloudsReducer = reducer;

export const cloudsActions = { ...actions, asyncActions };
