import { tauriFetch } from '@readcloud/fetch-wrapper';

import { ABSOLUTE_PATH } from '../../constants';

export const GetPublishersThunkAction = async (
  payload: string | void, // userEmail
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(
    ABSOLUTE_PATH +
      '/api/v14/get_publishers' +
      (payload ? `?${payload}` : ''),
    {
      method: 'GET',
      mode: 'cors',
      headers: headers,
    }
  ).then((response) => response?.json().then(({ result }) => result));
};
