import { Order, OrderFilterOptions } from '@readcloud/data';
import { ordersActions } from './slice';
import { tauriFetch } from '@readcloud/fetch-wrapper';

import { ABSOLUTE_PATH } from '../../constants';

export const GetOrdersThunkAction = async (
  payload: OrderFilterOptions | void = {},
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  let tempQueryString = '';

  if (payload) {
    payload?.effectiveFromRange?.start &&
      (tempQueryString += `effectiveFromStart=${payload.effectiveFromRange?.start}&`);
    payload?.effectiveFromRange?.end &&
      (tempQueryString += `effectiveFromEnd=${payload.effectiveFromRange?.end}&`);
    payload?.salesStatus?.length &&
      (tempQueryString += `salesStatus=${payload.salesStatus?.join(',')}&`);
    payload?.operationsStatus?.length &&
      (tempQueryString += `operationsStatus=${payload.operationsStatus?.join(
        ','
      )}&`);
    payload?.financeStatus?.length &&
      (tempQueryString += `financeStatus=${payload.financeStatus?.join(',')}`);
  }

  return await tauriFetch(
    ABSOLUTE_PATH + '/api/v14/order/all?' + tempQueryString,
    {
      method: 'GET',
      mode: 'cors',
      headers: headers,
    }
  ).then((response) => response?.json().then(({ result }) => result));
};

export const GetOrderThunkAction = async (payload: string, { getState }) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(ABSOLUTE_PATH + '/api/v14/order/' + payload, {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  }).then((response) => response.json().then(({ result }) => result));
};
export const AddOrderThunkAction = async (payload: Order, { getState }) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(ABSOLUTE_PATH + `/api/v14/order`, {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(payload),
  }).then(() => {
    return payload;
  });
};

export const UpdateOrderThunkAction = async (
  payload: Partial<Order>,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(ABSOLUTE_PATH + `/api/v14/order`, {
    method: 'PATCH',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify([payload]),
  }).then((response) => response.json().then(({ result }) => result));
};

export const DeleteOrderThunkAction = async (id: string, { getState }) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(ABSOLUTE_PATH + `/api/v14/order/${id}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: headers,
  }).then(() => id);
};

export const SetOrderFilterOptionsThunkAction = async (
  data: OrderFilterOptions
) => {
  localStorage.setItem('orderpayload', JSON.stringify(data));
  return data;
};

export const GetOrderFilterOptionsThunkAction = async () => {
  const stringData = localStorage.getItem('orderpayload');
  return JSON.parse(stringData);
};

// Thunk Action - Does some Async stuff (or data manipulation) then dispatch the action creator.
export const GetMappedOrdersThunkAction = async (
  payload: void,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(ABSOLUTE_PATH + '/api/v14/multi_order/all', {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  }).then((response: any) => response.json().then(({ result }) => result));
};

export const SearchMappedOrdersThunkAction = async (
  payload: {
    limit: number;
    offset: number;
    order: string;
    resellerIds?: string[];
    disableLookup: {
      reseller: boolean;
      book: boolean;
    };
    root: any;
  },
  { getState, dispatch }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(
    ABSOLUTE_PATH + '/api/v14/search/advanced/multi_order',
    {
      method: 'POST',
      mode: 'cors',
      headers: headers,
      body: JSON.stringify(payload),
    }
  ).then((response) =>
    response
      .json()
      .then((data) =>
        payload.offset === 0
          ? ordersActions.searchMappedOrders(data)
          : ordersActions.searchMappedOrdersMore(data)
      )
  );
};

// Thunk Action - Does some Async stuff (or data manipulation) then dispatch the action creator.
export const GetOrderableBooksThunkAction = async (
  payload: void,
  { getState }
) => {
  //Do API HERE
  // post order to database
  // if successful update the state
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(ABSOLUTE_PATH + '/api/v14/get_orderable_books', {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  }).then((response) => response.json().then(({ result }) => result));
};

export const SearchMultiOrderTransactionsThunkAction = async (
  payload: {
    limit: number;
    offset: number;
    order: string;
    resellerIds?: string[];
    disableLookup: {
      reseller: boolean;
      user: boolean;
      institution: boolean;
    };
    root: any;
  },
  { getState }
) => {
  //Do API HERE
  // if successful update the state
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };
  console.log('SearchMultiOrderTransactionsThunkAction');

  let res = {
    result: {
      items: [],
      moreAvailable: false,
      nextOffset: 0,
      timeMs: Date.now(),
    },
  };

  return await tauriFetch(
    ABSOLUTE_PATH + '/api/v14/search/advanced/multi_order_transaction',
    {
      method: 'POST',
      mode: 'cors',
      headers: headers,
      body: JSON.stringify(payload),
    }
  ).then((response) =>
    response
      .json()
      .then((data) =>
        payload.offset === 0
          ? ordersActions.searchMultiOrderTransactionsAction(data)
          : ordersActions.searchMultiOrderTransactionsMoreAction(data)
      )
  );
};
