import { ProductBundle } from '@readcloud/data';
import { tauriFetch } from '@readcloud/fetch-wrapper';

import { ABSOLUTE_PATH } from '../../constants';

export const GetProductBundlesThunkAction = async (
  payload: void,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(ABSOLUTE_PATH + '/api/v14/product_bundle/all', {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  }).then((response) => response?.json().then(({ result }) => result));
};

export const AddProductBundleThunkAction = async (
  payload: ProductBundle,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(ABSOLUTE_PATH + `/api/v14/product_bundle`, {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(payload),
  }).then(() => {
    return payload;
  });
};

export const UpdateProductBundleThunkAction = async (
  payload: ProductBundle,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(ABSOLUTE_PATH + `/api/v14/product_bundle`, {
    method: 'PATCH',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(payload),
  }).then(() => {
    return payload;
  });
};

export const DeleteProductBundleThunkAction = async (
  id: string,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(ABSOLUTE_PATH + `/api/v14/product_bundle/${id}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: headers,
  }).then(() => id);
};
