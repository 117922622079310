import { tauriFetch } from '@readcloud/fetch-wrapper';

import { ABSOLUTE_PATH } from '../../constants';

export const GetResellersThunkAction = async (
  payload: { userEmail?: string } = {},
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(
    ABSOLUTE_PATH + '/get_resellers' + payload.userEmail
      ? `?${payload.userEmail}`
      : '',
    {
      method: 'GET',
      mode: 'cors',
      headers: headers,
    }
  ).then((response) => response?.json().then(({ result }) => result));
};
export const GetAllResellersThunkAction = async (
  payload: void,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(ABSOLUTE_PATH + '/api/v14/reseller/all', {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  }).then((response) => response?.json().then(({ result }) => result));
};
