import C from 'classnames';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { TestingProps } from '../../Types/Testing';

interface IModalProps extends TestingProps {
  children?: React.ReactNode;
  vertical?: 'left' | 'right'; // tick
  isOpen?: boolean; // tick
  autoFocus?: boolean;
  centered?: boolean; // tick
  size?: string; // tick
  toggle?: () => void; // tick
  role?: string; // tick
  labelledBy?: string; // tick
  keyboard?: boolean; //tick
  backdrop?: boolean | 'static'; //tick
  /** Not yet implemented */
  scrollable?: boolean;
  onEnter?: () => void;
  onExit?: () => void;
  onOpened?: () => void;
  onClosed?: () => void;
  className?: string;
  wrapClassName?: string;
  /** Not yet implemented */
  modalClassName?: string;
  /** Not yet implemented */
  backdropClassName?: string;
  contentClassName?: string;
  fade?: boolean; //tick
  /** Not yet implemented */
  cssModule?: object;
  /** Not yet implemented */
  zIndex?: number | string;
  innerRef?: React.RefObject<HTMLDivElement>; //tick
  unmountOnClose?: () => void;
  /** Not yet implemented */
  returnFocusAfterClose?: boolean;
  modalContentWidth?: number;
}

const sizeMapper: { [index: string]: string } = {
  xl: 'modal-xl',
  lg: 'modal-lg',
  sm: 'modal-sm',
};

//set to the same duration of fade transition.
const ANIMATION_DURATION = 150;

//if there there's a scroll bar, add 17px padding-right

//get an element. if it doesn't exist create it and attach it to the dom.
const getDomElement = (id: string, element: HTMLElement) => {
  if (!!document.getElementById(id)) {
    return document.getElementById(id);
  } else {
    const tempEl = document.createElement('div');
    // tslint:disable-next-line: no-unused-expression
    tempEl && tempEl.setAttribute('id', id);
    // tslint:disable-next-line: no-unused-expression
    tempEl && element && element.appendChild(tempEl);
    return tempEl;
  }
};

export const Modal: React.FunctionComponent<IModalProps> = ({
  backdrop = true,
  centered = true,
  fade = true,
  keyboard = true,
  ...props
}: IModalProps) => {
  const [show, setShow] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const [openStyles, setOpenStyles] = React.useState<{
    display: string;
    paddingRight: string;
  }>({
    display: 'block',
    paddingRight: '0px',
  });

  React.useEffect(() => {
    if (props.isOpen) {
      setOpenStyles({
        display: 'block',
        paddingRight:
          document.body.scrollHeight > window.innerHeight ? '17px' : '0px',
      });
    }
    return () => {
      //if the modal doesn't have a chance to fade out, make sure we remove modal-open class on unmount
      if (!document.querySelector('#modal-root').hasChildNodes()) {
        document.body.classList.remove('modal-open');
        document.body.style.paddingRight = '0px';
      }
    };
  }, [props.isOpen]);
  //ref to modal
  const modalRef = React.useRef<HTMLDivElement>(null);

  //get the 'modal-root' element in the DOM. This is where the modals are attached.
  const rootEl = getDomElement('modal-root', document.body);

  // close modal when clicking anywhere (if backdrop is set to true)
  // backdrop can be set to 'static', which is why we need the '=== true' here.
  React.useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (e.target === modalRef.current) {
        // tslint:disable-next-line: no-unused-expression
        props.toggle && props.toggle();
      }
    };

    backdrop === true &&
      modalRef &&
      modalRef.current &&
      window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, [modalRef.current, backdrop, props.toggle]);

  // use and remove keydown event listener
  React.useEffect(() => {
    //function that's called in the keydown callback.
    const escapeModal = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        // tslint:disable-next-line: no-unused-expression
        props.toggle && props.toggle();
      }
    };

    if (props.isOpen) {
      // tslint:disable-next-line: no-unused-expression
      keyboard && window.addEventListener('keydown', escapeModal);
    } else {
      window.removeEventListener('keydown', escapeModal);
    }
    return () => window.removeEventListener('keydown', escapeModal);
  }, [props.isOpen, keyboard, props.toggle]);

  // if isOpen is true, set open (flag to render modal) to true
  React.useEffect(() => {
    if (props.isOpen) {
      setOpen(true);
      document.body.classList.add('modal-open');
      document.body.style.paddingRight = openStyles.paddingRight;
    } else {
      // set show to false so it starts transitioning.
      setShow(false);
      if (fade) {
        //if fade, wait for a timeout before doing things.
        requestAnimationFrame(() => {
          setTimeout(() => {
            setOpen(false);
            //if there are no other modals open, we can remove the modal-open class
            if (!document.querySelector('#modal-root').hasChildNodes()) {
              document.body.classList.remove('modal-open');
              document.body.style.paddingRight = '0px';
            }
          }, ANIMATION_DURATION);
        });
      } else {
        // else do everything instantly.
        setOpen(false);
        if (!document.querySelector('#modal-root').hasChildNodes()) {
          document.body.classList.remove('modal-open');
        }
      }
    }
  }, [props.isOpen, backdrop, fade]);

  //when open changes, if true, create backdrop element,
  //if fade, wait for next animation frame and then set show to true and add show to backdrop,
  //if not fade, do it instantly.
  React.useEffect(() => {
    if (open) {
      // blur the button clicked to open the modal, so we don't get any accessibility warnings
      (document.activeElement as any).blur();
      if (fade) {
        // seems to be a bug where requestAnimationFrame isn't quite waiting for the next frame (atleast in chrome).
        // waiting for 2 frames works.
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            // add show to the backdrop element.

            setShow(true);
          });
        });
      } else {
        //do everything instantly if fade is false(or null)
        setShow(true);
      }
    }
  }, [open, backdrop, fade]);

  return open && rootEl
    ? ReactDOM.createPortal(
        <>
          <div
            className={C(
              'modal',
              fade && 'fade',
              show && 'show',
              props.wrapClassName && props.wrapClassName,
              props.vertical === 'right' && 'fixed-right'
            )}
            tabIndex={-1}
            role={props.role ? props.role : 'dialog'}
            style={{ display: 'block' }}
            ref={modalRef}
            aria-labelledby={props.labelledBy && props.labelledBy}
            aria-hidden="true"
            data-testid={props.testId}
          >
            <div
              className={C(
                'modal-dialog',
                centered && 'modal-dialog-centered',
                props.size && sizeMapper[props.size],
                props.className && props.className,
                props.vertical && 'modal-dialog-vertical'
              )}
              role="document"
              ref={props.innerRef}
            >
              <div
                className={C('modal-content', props.contentClassName)}
                style={
                  props.modalContentWidth
                    ? { width: props.modalContentWidth }
                    : {}
                }
              >
                {React.Children.map(props.children, (child) => {
                  if (React.isValidElement(child)) {
                    return !!props.toggle
                      ? React.cloneElement(child, {
                          // TODO: Add typying to unknown child type
                          // @ts-expect-error
                          toggle: props.toggle,
                        })
                      : child;
                  }
                  return child;
                })}
              </div>
            </div>
          </div>
          {backdrop && (
            <div
              id="modal-backdrop"
              className={C('modal-backdrop', fade && 'fade', show && 'show')}
            ></div>
          )}
        </>,
        rootEl
      )
    : null;
};
