import { transactionsActions } from './slice';
import { tauriFetch } from '@readcloud/fetch-wrapper';

import { ABSOLUTE_PATH } from '../../constants';

// Thunk Action - Does some Async stuff (or data manipulation) then dispatch the action creator.
export const SearchAdvancedTransactionsThunkAction = async (
  payload: {
    isCsvSearch: boolean;
    vchildren: any;
    voffset: number;
    vlimit: number;
    pJoinMethod?: string;
  },
  { getState }
) => {
  console.log('SearchAdvancedTransactionsThunkAction');

  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };
  tauriFetch(ABSOLUTE_PATH + '/api/v14/search/advanced/transaction', {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify({
      csvOutput: false,
      disableLookup: {
        institution: true,
        reseller: true,
        user: true,
      },
      limit: payload.vlimit,
      offset: payload.voffset,
      order: '-date',
      root: {
        joinMethod: payload.pJoinMethod ? payload.pJoinMethod : 'and',
        children: payload.vchildren,
      },
    }),
  }).then((response) =>
    response
      .json()
      .then(({ result }) => {
        /*const data = {
            result: {
              items: dummyTransactionSearchData.result.items,
              moreAvailable: dummyTransactionSearchData.result.moreAvailable,
              nextOffset: dummyTransactionSearchData.result.nextOffset
            }
          };
          return Promise.resolve(dispatch(SearchAdvancedTransactionsAction(data)));*/
        //console.log(response);
        try {
          const data = {
            result: {
              items: result ? result.items : [],
              moreAvailable: result ? result.moreAvailable : false,
              nextOffset: result ? result.nextOffset : 0,
              timeMs: Date.now(),
            },
          };
          if (payload.isCsvSearch) {
            return transactionsActions.searchCSVTransactions(data);
          }
          return transactionsActions.searchAdvancedTransactions(data);
        } catch (e) {
          console.log(
            'Exception in SearchAdvancedTransactionsThunkAction ' + e
          );
        }
      })
      .catch((error: any) => {
        console.log('API call error: ' + error);
        const data = {
          result: {
            items: [],
            moreAvailable: false,
            nextOffset: 0,
            timeMs: Date.now(),
          },
        };
        if (payload.isCsvSearch) {
          return transactionsActions.searchCSVTransactions(data);
        }
        return transactionsActions.searchAdvancedTransactions(data);
      })
  );
};

export const DeleteTransactionsThunkAction = async (
  payload: {
    ids: string[];
  },
  { getState }
) => {
  console.log('DeleteTransactionsThunkAction');

  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  const tIds = [];
  payload.ids.forEach((line) => {
    tIds.push({
      id: line,
    });
  });

  return await tauriFetch(ABSOLUTE_PATH + '/api/v14/transaction', {
    method: 'DELETE',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(tIds),
  }).then((response) =>
    response.json().then(({ result }) => {
      return payload.ids;
    })
  );
};
