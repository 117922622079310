import cn from 'classnames';
import React, { CSSProperties, useState } from 'react';
import Transition, {
  TransitionProps,
  TransitionStatus,
} from 'react-transition-group/Transition';
import { TestingProps } from '../../Types/Testing';

export type ICollapseProps = TransitionProps &
  TestingProps & {
    innerRef?: React.RefObject<HTMLDivElement>;
    isOpen?: boolean;
    appear?: boolean;
    enter?: boolean;
    exit?: boolean;
    className?: string;
    style?: CSSProperties;
    navbar?: boolean;
    children?: React.ReactNode;
    unmountOnExit?: boolean;
    mountOnEnter?: boolean;
    HtmlTag?: any;
  };

const transitionMapper: { [index: string]: string } = {
  entering: 'collapsing',
  entered: 'collapse show',
  exiting: 'collapsing',
  exited: 'collapse',
};

function getTransitionClass(status: string) {
  return transitionMapper[status] || 'collapse';
}

const getHeight = (node: HTMLElement) => {
  return node.scrollHeight;
};

export const Collapse: React.FunctionComponent<ICollapseProps> = ({
  HtmlTag = 'div',
  ...props
}) => {
  const [height, setHeight] = useState<number | null>(null);

  const onEntering = (node: HTMLElement, isAppearing: boolean) => {
    const _unused = node.offsetHeight; // eslint-disable-line no-unused-vars
    setHeight(getHeight(node));
    props.onEntering && props.onEntering(node, isAppearing);
  };

  const onEntered = (node: HTMLElement, isAppearing: boolean) => {
    const _unused = node.offsetHeight; // eslint-disable-line no-unused-vars
    setHeight(null);
    props.onEntered && props.onEntered(node, isAppearing);
  };

  const onExit = (node: HTMLElement) => {
    const _unused = node.offsetHeight; // eslint-disable-line no-unused-vars
    setHeight(getHeight(node));
    props.onExit && props.onExit(node);
  };

  const onExiting = (node: HTMLElement) => {
    // getting this variable triggers a reflow
    // @ts-ignore
    const _unused = node.offsetHeight; // eslint-disable-line no-unused-vars
    setHeight(0);
    props.onExiting && props.onExiting(node);
  };

  const onExited = (node: HTMLElement) => {
    const _unused = node.offsetHeight; // eslint-disable-line no-unused-vars
    setHeight(null);
    props.onExited && props.onExited(node);
  };

  return (
    <Transition
      {...props}
      in={props.isOpen}
      appear={props.appear}
      unmountOnExit={props.unmountOnExit}
      mountOnEnter={props.mountOnEnter}
      timeout={props.timeout}
      onEntering={onEntering}
      onEntered={onEntered}
      onExit={onExit}
      onExiting={onExiting}
      onExited={onExited}
    >
      {(status: TransitionStatus) => {
        let collapseClass = getTransitionClass(status);

        const classes = cn(
          props.className,
          collapseClass,
          props.navbar && 'navbar-collapse'
        );
        const style = height === null ? null : { height };
        let transitionStyle = props.timeout
          ? { transitionDuration: props.timeout + 'ms' }
          : null;
        return (
          <HtmlTag
            style={{ ...props.style, ...style, ...transitionStyle }}
            className={classes}
            ref={props.innerRef}
            aria-expanded={props.isOpen ? 'true' : 'false'}
            data-testid={props.testId}
          >
            {props.children}
          </HtmlTag>
        );
      }}
    </Transition>
  );
};

export default Collapse;
