interface FetchOptions extends RequestInit {
  useTauri?: boolean;
}

function shouldIntercept(url: string, method: string): boolean {
  const fileExtension = url.split('.').pop()?.toLowerCase();
  const extensions = ['pdf', 'epub', 'jpg', 'png', 'jpeg'];

  // Check if URL matches any of our patterns
  const urlPatterns = [
    '*.readcloud.com/**',
    '*.readcloud-test.com/**',
    'localhost:4200/**',
    'localhost:4300/**',
  ];

  const urlMatch = urlPatterns.some((pattern) => {
    const regex = new RegExp(pattern.replace(/\*/g, '.*'));
    return regex.test(url);
  });

  const extensionMatch = extensions.includes(fileExtension);
  const methodMatch = method === 'GET' || method === 'HEAD';

  return urlMatch && extensionMatch && methodMatch;
}

function generateRequestId(): string {
  return Math.random().toString(36).substr(2, 9);
}

async function sendMessageToBridge(type: string, data: any): Promise<void> {
  window.dispatchEvent(
    new CustomEvent(type, {
      detail: data,
    })
  );
}

async function handleTauriFetch(
  url: string,
  options: FetchOptions = {}
): Promise<Response> {
  const requestId = generateRequestId();

  // Create a promise that will be resolved when we get a response from the bridge
  const bridgeResponse = new Promise<Response>((resolve, reject) => {
    const messageHandler = (event: CustomEvent) => {
      if (
        event.detail &&
        event.detail.type === 'bridgeResponse' &&
        event.detail.requestId === requestId
      ) {
        window.removeEventListener(
          'tauriFetchResponse',
          messageHandler as EventListener
        );

        if (event.detail.success) {
          // Create a new Response with the stored content and original headers
          const response = new Response(event.detail.content, {
            status: 200,
            headers: new Headers(event.detail.headers || {}),
          });
          resolve(response);
        } else {
          // If bridge request fails, fetch from network and save to storage
          console.log(`Bridge request failed: ${event.detail.error}`);
          console.log('fetching from network', options);

          // Use Tauri's fetch for network requests
          import('@tauri-apps/plugin-http').then(({ fetch }) => {
            fetch(url, {
              method: options.method,
              headers: options.headers as Record<string, string>,
              body: options.body,
              credentials: options.credentials,
              mode: options.mode,
              redirect: options.redirect,
              signal: options.signal,
            })
              .then(async (response) => {
                if (!response.ok) {
                  throw new Error(`HTTP error! status: ${response.status}`);
                }
                const content = await response.arrayBuffer();

                // Convert response headers to a plain object, excluding range-related headers
                const headers: Record<string, string> = {};

                response.headers.forEach((value, key) => {
                  // Skip range-related headers when saving to storage
                  if (!key.toLowerCase().includes('range')) {
                    headers[key] = value;
                  }
                });

                // Save the fetched content to storage with filtered headers
                const saveRequestId = generateRequestId();
                await sendMessageToBridge('saveFileToStorage', {
                  requestId: saveRequestId,
                  url,
                  content,
                  headers,
                });

                // Return a new Response with filtered headers
                resolve(
                  new Response(content, {
                    status: response.status,
                    headers: new Headers(headers),
                  })
                );
              })
              .catch(reject);
          });
        }
      }
    };

    window.addEventListener(
      'tauriFetchResponse',
      messageHandler as EventListener
    );
  });

  // Send the request to the bridge
  await sendMessageToBridge('getFileFromStorage', {
    requestId,
    url,
  });

  return bridgeResponse;
}

async function basicTauriRequest(
  url: string,
  options: FetchOptions = {}
): Promise<Response> {
  const isTauri = typeof window !== 'undefined' && '__TAURI__' in window;

  if (isTauri) {
    // Use Tauri's HTTP plugin
    const { fetch } = await import('@tauri-apps/plugin-http');
    return fetch(url, options);
  } else {
    // Use regular fetch for web environment
    return window.fetch(url, options);
  }
}

function getContentType(url: string): string | null {
  const extension = url.split('.').pop()?.toLowerCase();
  switch (extension) {
    case 'css':
      return 'text/css';
    case 'js':
      return 'application/javascript';
    case 'html':
      return 'text/html';
    case 'json':
      return 'application/json';
    case 'png':
      return 'image/png';
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'gif':
      return 'image/gif';
    case 'pdf':
      return 'application/pdf';
    case 'epub':
      return 'application/epub+zip';
    default:
      return null;
  }
}

export async function wrappedFetch(
  input: RequestInfo | URL,
  init?: RequestInit
): Promise<Response> {
  const url = input instanceof Request ? input.url : input.toString();
  const options = init || {};
  const isTauri = !!(window as any).__TAURI__;
  const shouldUseTauri = (options as FetchOptions).useTauri ?? isTauri;
  const method = options.method || 'GET';

  // Convert Headers object to plain object if present
  const processedOptions = {
    ...options,
    headers:
      options.headers instanceof Headers
        ? (() => {
            const headers: Record<string, string> = {};
            (options.headers as Headers).forEach((value, key) => {
              headers[key] = value;
            });
            return headers;
          })()
        : options.headers,
  };

  // If we're not in Tauri environment or shouldn't use Tauri, use regular fetch
  if (!shouldUseTauri) {
    return window.fetch(input, init);
  }

  // Check if this request should be intercepted
  if (shouldIntercept(url, method)) {
    return handleTauriFetch(url, processedOptions as FetchOptions);
  }

  // For non-intercepted requests, use basic Tauri request
  return basicTauriRequest(url, processedOptions as FetchOptions);
}

// Utility function to ensure we're using our wrapped fetch
export function ensureWrappedFetch(): typeof window.fetch {
  const isTauri = !!(window as any).__TAURI__;
  if (isTauri) {
    return wrappedFetch;
  }
  return window.fetch;
}

// Export the wrapped fetch with a more specific name
export const tauriFetch = wrappedFetch;

// Export a function that can be used to patch fetch in third-party code
export function patchFetch(target: any, propertyKey: string = 'fetch'): void {
  if (target && typeof target[propertyKey] === 'function') {
    const originalFetch = target[propertyKey];
    target[propertyKey] = function (
      input: RequestInfo | URL,
      init?: RequestInit
    ) {
      return ensureWrappedFetch()(input, init);
    };
  }
}
