import { ABSOLUTE_PATH } from '../../constants';
import { tauriFetch } from '@readcloud/fetch-wrapper';

export const GetEffectiveOrderItemsThunkAction = async (
  payload: string,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(
    ABSOLUTE_PATH + '/api/v14/grouped_order_items/' + payload,
    {
      method: 'GET',
      mode: 'cors',
      headers: headers,
    }
  ).then((response) => response.json().then(({ result }) => result));
};

export const GetTransactionOrderItemsThunkAction = async (
  payload: string,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(
    ABSOLUTE_PATH + '/api/v14/grouped_order_items_ex/' + payload,
    {
      method: 'GET',
      mode: 'cors',
      headers: headers,
    }
  ).then((response) => response.json().then(({ result }) => result));
};
