import App from './app/app';
import {
  Analytics,
  AuthenticatedSection,
  AuthProvider,
} from '@readcloud/common';
import { AppDispatch, reportActions } from '@readcloud/state';
import { TooltipProvider } from '@readcloud/ui';
import { Toaster } from '@readcloud/ui/sonner';
import * as React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { environment } from './environments/environment';
import { store } from './redux/store';
import { WebAppVersion, ContentVersion } from '@readcloud/common/config.json';
import { LoadingIcon } from '@readcloud/component-library';

interface IAppRootProps {}

const AppRoot: React.FunctionComponent<IAppRootProps> = (props) => {
  return (
    <React.Suspense
      fallback={
        <div className="rc-h-screen rc-w-full rc-flex rc-flex-col rc-items-center rc-justify-center rc-bg-gray-100">
          <LoadingIcon loading position="absolute" />
        </div>
      }
    >
      <Provider store={store}>
        <BrowserRouter>
          <QueryParamProvider adapter={ReactRouter5Adapter}>
            <AuthProvider
              client_id={environment.auth.client_id}
              authority={environment.auth.authority}
              signinCallback={() => {
                (store.dispatch as AppDispatch)(
                  reportActions.asyncActions.createUserActivity({
                    type: 'Login',
                    'App Name': 'Webapp',
                    'Webapp Version': WebAppVersion,
                    'Content Version': ContentVersion,
                  })
                );
              }}
            >
              <TooltipProvider>
                <Analytics apiKey={environment.posthog_id}>
                  <AuthenticatedSection>
                    <App />
                  </AuthenticatedSection>
                  <div
                    style={{ position: 'absolute' }}
                    className="readcloud-ui readcloud-ui-reset"
                  ></div>
                </Analytics>
              </TooltipProvider>
            </AuthProvider>
          </QueryParamProvider>
          <Toaster />
        </BrowserRouter>
      </Provider>
    </React.Suspense>
  );
};

export default AppRoot;
