import { AsyncJob } from '@readcloud/data';
import { jobsActions } from './slice';
import { tauriFetch } from '@readcloud/fetch-wrapper';

import { ABSOLUTE_PATH } from '../../constants';

export const HandleAsyncJobThunkAction = async (
  payload: {
    job: AsyncJob;
    timeout?: number;
    currentPhase?: number;
    onFinished?: (...args: any) => any; //action to dispatch once the process returns finished = true
  },
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };
  return tauriFetch('/api/v14/async_op_result/' + payload.job.id, {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  }).then((response) => {
    response.json().then(({ result }) => {
      if (!result.finished && !result.error) {
        setTimeout(
          () => {
            return jobsActions.handleAsyncJob({
              job: result,
              timeout: payload.timeout,
              currentPhase: result.progress.currentPhaseIdx,
              onFinished: payload.onFinished,
            });
          },
          payload.timeout && payload.timeout > 10 ? payload.timeout : 10
        );
      }
      //if we just finished, then create instead of updating
      // so that even if the user has closed the toast, a new one will appear.
      if (result.finished || result.error) {
        payload.onFinished &&
          !result.error &&
          payload.onFinished(result.result);
        return jobsActions.addAsyncJob(result);
      } else {
        return jobsActions.updateAsyncJob(result);
      }
    });
  });
};
