import { useEffect } from 'react';
import {
  readFile,
  writeFile,
  BaseDirectory,
  exists,
  create,
  mkdir,
} from '@tauri-apps/plugin-fs';

function sanitizeUrlToFilename(url: string | undefined): string {
  if (!url) {
    throw new Error('URL is required for file operations');
  }

  // Get the part of the URL after the last '/'
  const fileName = url.split('/').pop() || '';

  // Sanitize the fileName by replacing any unwanted characters
  return fileName.replace(/[\/:]/g, '_');
}

interface GetFileFromStorageEvent extends CustomEvent {
  detail: {
    url: string;
    requestId: string;
    headers?: Record<string, string>;
  };
}

interface SaveFileToStorageEvent extends CustomEvent {
  detail: {
    url: string;
    content: ArrayBuffer;
    requestId: string;
    headers?: Record<string, string>;
  };
}

export const useTauriFetchListener = () => {
  useEffect(() => {
    // Check if running in a Tauri environment
    const isNative = !!(window as any).__TAURI__;

    // Handle getFileFromStorage events
    const GetFileFromStorageListener = async (
      event: GetFileFromStorageEvent
    ) => {
      const { url, requestId, headers } = event.detail;

      if (!url) {
        console.error('No URL provided for file storage operation');
        window.dispatchEvent(
          new CustomEvent('tauriFetchResponse', {
            detail: {
              type: 'bridgeResponse',
              requestId,
              success: false,
              error: 'No URL provided for file storage operation',
            },
          })
        );
        return;
      }

      try {
        const sanitizedFileName = sanitizeUrlToFilename(url);

        const fileContent = await readFile(`readcloud/${sanitizedFileName}`, {
          baseDir: BaseDirectory.AppData,
        });


        // Dispatch response event with preserved headers
        window.dispatchEvent(
          new CustomEvent('tauriFetchResponse', {
            detail: {
              type: 'bridgeResponse',
              requestId,
              success: true,
              content: fileContent,
              headers: headers || {},
            },
          })
        );
      } catch (error) {
        console.error(`Error reading file from storage: ${url}`, error);
        // Dispatch error response event
        window.dispatchEvent(
          new CustomEvent('tauriFetchResponse', {
            detail: {
              type: 'bridgeResponse',
              requestId,
              success: false,
              error: `Error: Could not fetch file ${url}`,
            },
          })
        );
      }
    };

    // Handle saveFileToStorage events
    const SaveFileToStorageListener = async (event: SaveFileToStorageEvent) => {
      const { url, content, requestId, headers } = event.detail;

      if (!url) {
        console.error('No URL provided for file storage operation');
        window.dispatchEvent(
          new CustomEvent('tauriFetchResponse', {
            detail: {
              type: 'bridgeResponse',
              requestId,
              success: false,
              error: 'No URL provided for file storage operation',
            },
          })
        );
        return;
      }

      try {
        const sanitizedFileName = sanitizeUrlToFilename(url);

        await mkdir('readcloud', {
          baseDir: BaseDirectory.AppData,
          recursive: true,
        });

        // Convert ArrayBuffer to Uint8Array
        const contentAsUint8Array = new Uint8Array(content);

        await create(`readcloud/${sanitizedFileName}`, {
          baseDir: BaseDirectory.AppData,
        });


        // Store the file in the filesystem for future requests
        await writeFile(`readcloud/${sanitizedFileName}`, contentAsUint8Array, {
          baseDir: BaseDirectory.AppData,
        });

        // Dispatch success response event with preserved headers
        window.dispatchEvent(
          new CustomEvent('tauriFetchResponse', {
            detail: {
              type: 'bridgeResponse',
              requestId,
              success: true,
              content,
              headers: headers || {},
            },
          })
        );
      } catch (error) {
        console.error(`Failed to write file: ${url}`, error);
        // Dispatch error response event
        window.dispatchEvent(
          new CustomEvent('tauriFetchResponse', {
            detail: {
              type: 'bridgeResponse',
              requestId,
              success: false,
              error: `Error: Could not save file ${url}`,
            },
          })
        );
      }
    };

    // Register event listeners
    window.addEventListener(
      'getFileFromStorage',
      GetFileFromStorageListener as unknown as EventListener
    );
    window.addEventListener(
      'saveFileToStorage',
      SaveFileToStorageListener as unknown as EventListener
    );

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener(
        'getFileFromStorage',
        GetFileFromStorageListener as unknown as EventListener
      );
      window.removeEventListener(
        'saveFileToStorage',
        SaveFileToStorageListener as unknown as EventListener
      );
    };
  }, []);
};
