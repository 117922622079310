import { Annotation } from '@readcloud/data';
import { trackEvent } from '@root/libs/common/src';
import { tryCatchAndNotify } from '@root/libs/common/src/lib/rum';
import { applicationActions } from '../../reader-features';
import { getReaderAnalyticsContext } from '../../analytics';
import { commentsActions } from '../comments';
import { tauriFetch } from '@readcloud/fetch-wrapper';
import { ABSOLUTE_PATH } from '../../constants';

export const GetConnectedAnnotationsThunkAction = async (
  payload: {
    filterBookIds?: string[];
    userEmail?: string;
  },
  { dispatch, getState }
) => {
  const getAllQueryOpts = {
    upToVersion: '3',
    userOpts: { get: false },
    bookOpts: { get: false },
    annotationOpts: {
      get: true,
    },
    bookmarkOpts: { get: false },
    cloudOpts: { get: false },
    commentOpts: { get: true },
    tagOpts: { get: false },
    appStateOpts: { get: false },
    filterBookIds: payload.filterBookIds,
    userEmail: payload.userEmail,
  };

  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(ABSOLUTE_PATH + '/api/rc/v14/get/all', {
    method: 'POST',
    // mode: 'cors',
    headers: headers,
    body: JSON.stringify(getAllQueryOpts),
  }).then((response) =>
    response.json().then(({ result }) => {
      dispatch(applicationActions.setAllServerMilliSec(result?.serverMillisec));
      result?.comments &&
        dispatch(commentsActions.setComments(result?.comments));
      const annotationByPage = result?.annotations?.reduce(
        (acc: { [pageNumber: string]: Annotation[] }, next: Annotation) =>
          next?.location
            ? {
                ...acc,
                [next?.location?.pageNumber]: [
                  ...(acc[next?.location?.pageNumber] || []),
                  next,
                ],
              }
            : acc
      );
      return {
        annotations: result?.annotations || [],
        annotationsByPage: annotationByPage || {},
      };
    })
  );
};

export const AddConnectedAnnotationThunkAction = async (
  payload: Annotation,
  { getState }
) => {
  tryCatchAndNotify(() => {
    trackEvent('Webapp/AnnotationCreated', {
      annotation: {
        color: payload.color,
        id: payload.id,
        location: payload.location,
        type: payload.type,
        selectedText: payload.selectedText,
        version: payload.version,
        options: payload.options,
      },
      sharedWithOthers:
        payload.visibleInClouds.length > 0 || payload.visibleToUsers.length > 0,
      ...getReaderAnalyticsContext(getState),
    });
  });
  const name = 'annotation';
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  // the expected Annotation from the API has slightly different typing, so we manipulate it here.
  const expectedPayload = {
    ...payload,
    options: payload.options || {
      status: null,
      type: null,
    },
    textFormat: 'PLAIN',
    authorID: payload.author,
    bookID: payload.book,
    text: 'text' in payload ? payload.text || '' : '',
    version: payload.version || '2',
    type: 'type' in payload && payload.type ? payload.type : 'Text',
    location: 'location' in payload ? payload.location : undefined,
    data: 'data' in payload ? payload.data : undefined,
  };

  tauriFetch(ABSOLUTE_PATH + `/api/v14/add/${name}`, {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(expectedPayload),
  });
  return payload;
};

export const UpdateConnectedAnnotationThunkAction = async (
  payload: Annotation,
  { getState }
) => {
  tryCatchAndNotify(() => {
    trackEvent('Webapp/AnnotationUpdated', {
      annotation: {
        color: payload.color,
        id: payload.id,
        location: payload.location,
        type: payload.type,
        selectedText: payload.selectedText,
        version: payload.version,
        options: payload.options,
      },
      sharedWithOthers:
        payload.visibleInClouds.length > 0 || payload.visibleToUsers.length > 0,
      ...getReaderAnalyticsContext(getState),
    });
  });
  const name = 'annotation';

  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  // the expected Annotation from the API has slightly different typing, so we manipulate it here. We should synchronise this.
  const expectedPayload = {
    ...payload,
    clientUpdated: new Date().toISOString(),
    options: payload.options || {
      status: null,
      type: null,
    },
    bookID: payload.book,
    authorID: payload.author,
    location:
      'location' in payload && payload.location ? payload.location : undefined,
    selectedText: payload.selectedText,
    text: 'text' in payload ? payload.text || '' : '',
    textFormat: 'PLAIN',
    version: payload.version || '2',
    type: 'type' in payload && payload.type ? payload.type : 'Text',
    data: 'data' in payload ? payload.data : undefined,
  };

  tauriFetch(ABSOLUTE_PATH + `/api/v14/update/${name}?id=${payload.id}`, {
    method: 'PUT',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(expectedPayload),
  });
  return payload;
};

export const DeleteConnectedAnnotationThunkAction = async (
  id: string,
  { getState }
) => {
  tryCatchAndNotify(() => {
    const currentAnnotation = getState().AnnotationsState.annotations.find(
      (anno) => anno.id === id
    );

    trackEvent('Webapp/AnnotationDeleted', {
      annotation: {
        color: currentAnnotation.color,
        id: currentAnnotation.id,
        location: currentAnnotation.location,
        type: currentAnnotation.type,
        selectedText: currentAnnotation.selectedText,
        version: currentAnnotation.version,
        options: currentAnnotation.options,
      },
      sharedWithOthers:
        currentAnnotation.visibleInClouds.length > 0 ||
        currentAnnotation.visibleToUsers.length > 0,
      ...getReaderAnalyticsContext(getState),
    });
  });

  const name = 'annotation';
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  tauriFetch(ABSOLUTE_PATH + `/api/v14/delete/${name}?id=${id}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: headers,
  });
  return id;
};

export const SetAnnotationFilter = async (payload: {
  types: string;
  users: string[];
}) => {
  return payload;
};

export const GetDeltaConnectedAnnotationsThunkAction = async (
  payload: {
    filterBookIds?: string[];
    userEmail?: string;
  },
  { getState, dispatch }
) => {
  const getAllQueryOpts = {
    upToVersion: '3',
    filterBookIds: payload.filterBookIds,
    userEmail: payload.userEmail,
  };

  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  const params = new URLSearchParams({
    millisec:
      getState().ApplicationState.lastPoll.annotationsServerMillisec || 0,
    upToVersion: '3',
    userEmail: payload.userEmail || '',
  });

  return await tauriFetch(
    ABSOLUTE_PATH + `/api/v14/delta/annotations?` + params.toString(),
    {
      method: 'GET',
      mode: 'cors',
      headers: headers,
    }
  ).then(async (response) => {
    if (!response.ok) {
      throw new Error();
    }
    const { result } = await response.json();
    dispatch(
      applicationActions.setAnnotationsServerMilliSec(result?.serverMillisec)
    );
    return result.annotation;
  });
};
