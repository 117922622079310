import { AppModule } from '@readcloud/data';
import { tauriFetch } from '@readcloud/fetch-wrapper';
import { ABSOLUTE_PATH } from '../../constants';

export const GetAppModulesThunkAction = async (payload: void, { getState }) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(ABSOLUTE_PATH + '/api/v14/app_module/all', {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  }).then((response) => response?.json().then(({ result }) => result));
};

export const UpdateAppModuleThunkAction = async (
  payload: AppModule,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(ABSOLUTE_PATH + `/api/v14/app_module/${payload.id}`, {
    method: 'PATCH',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(payload),
  }).then(() => {
    return payload;
  });
};
