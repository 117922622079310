import { StreamImportRule } from '@readcloud/data';
import { tauriFetch } from '@readcloud/fetch-wrapper';

import { ABSOLUTE_PATH } from '../../constants';

export const GetStreamImportDataThunkAction = async (
  payload: {
    dateFrom: string; // '2012-12-15T00:00:22.972+0000'
    dateTo: string;
    skip: number;
    limit: number;
    isNewSearch: boolean;
    institutionId?: string;
    realm?: string;
  },
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  let res = {
    result: {
      items: [],
      moreAvailable: false,
      nextOffset: 0,
    },
    addToPrevResult: false,
    serverMillisec: 0,
  };

  const req = {
    limit: payload.limit,
    offset: payload.skip,
    order: '-date',
    root: {
      joinMethod: 'and',
      children: [
        {
          key: 'date',
          op: 'GreaterThanOrEq',
          valueType: 'Date',
          value: payload.dateFrom,
        },
        {
          key: 'date',
          op: 'LessThanOrEq',
          valueType: 'Date',
          value: payload.dateTo,
        },
        {
          key: 'realm',
          op: 'Equals',
          valueType: 'String',
          value: payload.realm ? payload.realm : 'StreamImportReport',
        },
      ],
    },
  };

  if (payload.institutionId)
    req.root.children.push({
      key: 'institutionId',
      op: 'Equals',
      valueType: 'ObjectId',
      value: payload.institutionId,
    });

  try {
    await tauriFetch(ABSOLUTE_PATH + '/api/v14/search/advanced/log', {
      method: 'POST',
      mode: 'cors',
      headers: headers,
      body: JSON.stringify(req),
    }).then((response) =>
      response.json().then(async (data) => {
        if (data?.result) {
          //console.log(response.data.result);
          res = data;
          res.addToPrevResult = !payload.isNewSearch;
          res.serverMillisec = Date.now();

          // transaction count
          if (res.result.items.length > 0) {
            const jobIds = [];
            res.result.items.forEach((l) => {
              jobIds.push(l.logRecord.jobId);
            });
            let reqPrm = 'product_transaction/count_by_jobid?';
            if (payload.institutionId && payload.institutionId.length > 0)
              reqPrm += 'institutionId=' + payload.institutionId;
            if (jobIds && jobIds.length > 0)
              reqPrm += '&origJobIds=' + jobIds.join(',');

            await tauriFetch(reqPrm, {
              method: 'GET',
              mode: 'cors',
              headers: headers,
            }).then((response) =>
              response.json().then(({ result }) => {
                if (result) {
                  //console.log(r2.data.result);
                  const map = new Map<string, string>();
                  for (const value in result) {
                    if (result.hasOwnProperty(value))
                      map.set(value, result[value]);
                  }
                  //console.log("map:" + map.size);
                  res.result.items.forEach((l) => {
                    const tc = map.get(l.logRecord.jobId);
                    l.logRecord.transactionCount = tc ? tc : -1;
                  });
                  //console.log(res);
                }
              })
            );
          }
        }
      })
    );
  } catch (e) {
    console.log('Error in GetStreamImportDataThunkAction ' + e);
  }

  return res;
};

export const SearchStreamImportRulesThunkAction = async (
  payload: {
    vchildren: any;
    voffset: number;
    vlimit: number;
    pJoinMethod?: string;
  },
  { getState }
) => {
  console.log('SearchStreamImportRulesThunkAction');

  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(
    ABSOLUTE_PATH + '/api/v14/search/advanced/stream_import_rule_meta',
    {
      method: 'POST',
      mode: 'cors',
      headers: headers,
      body: JSON.stringify({
        limit: payload.vlimit,
        offset: payload.voffset,
        order: '-updated',
        root: {
          joinMethod: payload.pJoinMethod ? payload.pJoinMethod : 'and',
          children: payload.vchildren,
        },
      }),
    }
  ).then(async (response) => {
    return await response
      .json()
      .then(({ result }) => {
        try {
          //console.log(response);
          const data = {
            result: {
              items: result ? result.items : [],
              moreAvailable: result ? result.moreAvailable : false,
              nextOffset: result ? result.nextOffset : 0,
              timeMs: Date.now(),
            },
          };
          return data;
        } catch (e) {
          console.log('Exception in SearchStreamImportRulesThunkAction ' + e);
        }
      })
      .catch((error: any) => {
        console.log('API call error: ' + error);
        const data = {
          result: {
            items: [],
            moreAvailable: false,
            nextOffset: 0,
            timeMs: Date.now(),
          },
        };
        return data;
      });
  });
};

export const AddStreamImportRuleThunkAction = async (
  payload: {
    rule: Partial<StreamImportRule>;
  },
  { getState }
) => {
  console.log('AddStreamImportRuleThunkAction');

  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(
    ABSOLUTE_PATH + '/api/v14/search/advanced/stream_import_rule_meta',
    {
      method: 'POST',
      mode: 'cors',
      headers: headers,
      body: JSON.stringify([payload.rule]),
    }
  )
    .then((response) =>
      response.json().then(({ result }) => {
        try {
          //console.log(response);
          if (result) return result[0];
        } catch (e) {
          console.log('Exception in AddStreamImportRuleThunkAction ' + e);
        }
      })
    )
    .catch((error: any) => {
      console.log('API call error: ' + error);
      return null;
    });
};

export const UpdateStreamImportRuleThunkAction = async (
  payload: {
    ruleId: string;
    rule: Partial<StreamImportRule>;
  },
  { getState }
) => {
  console.log('UpdateStreamImportRuleThunkAction');

  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(ABSOLUTE_PATH + '/api/v14/stream_import_rule_meta/', {
    method: 'PATCH',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify([payload.rule]),
  }).then((response) =>
    response
      .json()
      .then(({ result }) => {
        try {
          //console.log(response);
          if (result) return result[0];
        } catch (e) {
          console.log('Exception in UpdateStreamImportRuleThunkAction ' + e);
        }
      })
      .catch((error: any) => {
        console.log('API call error: ' + error);
        return null;
      })
  );
};

export const DeleteStreamImportRuleThunkAction = async (
  payload: {
    ruleId: string;
  },
  { getState }
) => {
  console.log('DeleteStreamImportRuleThunkAction');

  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(
    ABSOLUTE_PATH + '/api/v14/stream_import_rule_meta/' + payload.ruleId,
    {
      method: 'DELETE',
      mode: 'cors',
      headers: headers,
    }
  ).then((response) =>
    response
      .json()
      .then(() => {
        try {
          //console.log(response);
          return payload.ruleId;
        } catch (e) {
          console.log('Exception in DeleteStreamImportRuleThunkAction ' + e);
        }
      })
      .catch((error: any) => {
        console.log('API call error: ' + error);
        return null;
      })
  );
};
