import { Institution } from '@readcloud/data';
import { tauriFetch } from '@readcloud/fetch-wrapper';

import { ABSOLUTE_PATH } from '../../constants';

export const GetInstitutionsThunkAction = async (
  payload: void,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(ABSOLUTE_PATH + '/api/v14/institution/all', {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  }).then((response) => response.json().then(({ result }) => result));
};
export const GetInstitutionThunkAction = async (id: string, { getState }) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(ABSOLUTE_PATH + `/api/v14/institution/${id}`, {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  }).then((response) => response.json().then(({ result }) => result));
};

export const AddInstitutionThunkAction = async (
  payload: Institution,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(ABSOLUTE_PATH + `/api/v14/institution`, {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(payload),
  }).then(() => {
    return payload;
  });
};

export const UpdateInstitutionThunkAction = async (
  payload: Institution,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(ABSOLUTE_PATH + `/api/v14/institution`, {
    method: 'PATCH',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(payload),
  }).then(() => {
    return payload;
  });
};

export const DeleteInstitutionThunkAction = async (
  id: string,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(ABSOLUTE_PATH + `/api/v14/institution/${id}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: headers,
  }).then(() => id);
};

export const updateInstitutionSpecificRegexps = async (
  payload: {
    institutionId: string;
    specificRegexps: Partial<
      Institution['classNameMappings']['specificRegexps']
    >;
  },
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  await tauriFetch(
    '/api/v14/institution/' + payload.institutionId + '/specific_regexps',
    {
      method: 'PUT',
      mode: 'cors',
      headers: headers,
      body: JSON.stringify(payload.specificRegexps),
    }
  );
  return payload;
};

export const updateInstitutionGenericRegexps = async (
  payload: {
    institutionId: string;
    genericRegexps: Partial<Institution['classNameMappings']['genericRegexps']>;
  },
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  await tauriFetch(
    '/api/v14/institution/' + payload.institutionId + '/generic_regexps',
    {
      method: 'PUT',
      mode: 'cors',
      headers: headers,
      body: JSON.stringify(payload.genericRegexps),
    }
  );

  return payload;
};

export const updateInstitutionSubjects = async (
  payload: {
    institutionId: string;
    subjects: Partial<Institution['classNameMappings']['subjects']>;
  },
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  await tauriFetch(
    'https://api.readcloud.com/api/v14/institution/' +
      payload.institutionId +
      '/subjects',
    {
      method: 'PUT',
      mode: 'cors',
      headers: headers,
      body: JSON.stringify(payload.subjects),
    }
  );
  return payload;
};

export const updateInstitutionYearLevels = async (
  payload: {
    institutionId: string;
    yearLevels: Partial<Institution['classNameMappings']['yearLevels']>;
  },
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  await tauriFetch(
    '/api/v14/institution/' + payload.institutionId + '/year_levels',
    {
      method: 'PUT',
      mode: 'cors',
      headers: headers,
      body: JSON.stringify(payload.yearLevels),
    }
  );
  return payload;
};

export const GetInstitutionReportsThunkAction = async (
  payload: {
    id: string;
    fromDate?: string;
    toDate?: string;
  },
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return tauriFetch(
    `/get_publisher_reports/${payload.id}?${
      payload.fromDate ? 'dateFrom=' + payload.fromDate : ''
    }&${payload.toDate ? 'dateTo=' + payload.toDate : ''}`,
    {
      headers,
    }
  ).then((response) => response.json().then(({ result }) => result));
};
