import { Bookmark, reduxBookmarkToReaderBookmark } from '@readcloud/data';
import { trackEvent } from '@root/libs/common/src';
import { tryCatchAndNotify } from '@root/libs/common/src/lib/rum';
import { applicationActions } from '../../reader-features';
import { getReaderAnalyticsContext } from '../../analytics';
import { tauriFetch } from '@readcloud/fetch-wrapper';

import { ABSOLUTE_PATH } from '../../constants';

export const GetConnectedBookmarksThunkAction = async (
  payload: {
    filterBookIds?: string[];
    userEmail?: string;
  },
  { dispatch, getState, requestId }
) => {
  const getAllQueryOpts = {
    upToVersion: '3',
    userOpts: { get: false },
    bookOpts: { get: false },
    annotationOpts: { get: false },
    bookmarkOpts: { get: true },
    cloudOpts: { get: false },
    commentOpts: { get: false },
    tagOpts: { get: false },
    appStateOpts: { get: false },
    filterBookIds: payload.filterBookIds,
    userEmail: payload.userEmail,
  };

  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(ABSOLUTE_PATH + '/api/rc/v14/get/all', {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(getAllQueryOpts),
  }).then((response) =>
    response.json().then(({ result }) => {
      dispatch(applicationActions.setAllServerMilliSec(result?.serverMillisec));
      return result?.bookmarks;
    })
  );
};

export const AddConnectedBookmarkThunkAction = async (
  payload: Bookmark,
  { getState }
) => {
  tryCatchAndNotify(() => {
    trackEvent('Webapp/BookmarkCreated', {
      bookmark: {
        location: payload.location,
        text: payload.text,
        version: payload.version,
      },
      ...getReaderAnalyticsContext(getState),
    });
  });
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  // Webapp data requires some manipulating for the API to accept them. This is done here.
  const expectedPayload = reduxBookmarkToReaderBookmark(payload);

  return await tauriFetch(ABSOLUTE_PATH + `/api/v14/add/bookmark`, {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(expectedPayload),
  }).then(() => {
    return payload;
  });
};

export const UpdateConnectedBookmarkThunkAction = async (
  payload: Bookmark,
  { getState }
) => {
  tryCatchAndNotify(() => {
    trackEvent('Webapp/BookmarkCreated', {
      bookmark: {
        location: payload.location,
        text: payload.text,
        version: payload.version,
      },
      ...getReaderAnalyticsContext(getState),
    });
  });
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  // Webapp data requires some manipulating for the API to accept them. This is done here.
  const expectedPayload = reduxBookmarkToReaderBookmark(payload);

  return await tauriFetch(
    ABSOLUTE_PATH + `/api/v14/update/bookmark?id=${payload.id}`,
    {
      method: 'PUT',
      mode: 'cors',
      headers: headers,
      body: JSON.stringify(expectedPayload),
    }
  ).then(() => {
    return payload;
  });
};

export const DeleteConnectedBookmarkThunkAction = async (
  id: string,
  { getState }
) => {
  tryCatchAndNotify(() => {
    const bookmark = getState().BookmarksState.bookmarks.find(
      (b) => b.id === id
    );
    trackEvent('Webapp/BookmarkCreated', {
      bookmark: {
        location: bookmark.location,
        text: bookmark.text,
        version: bookmark.version,
      },
      ...getReaderAnalyticsContext(getState),
    });
  });
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await tauriFetch(ABSOLUTE_PATH + `/api/v14/delete/bookmark?id=${id}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: headers,
  }).then(() => id);
};

export const GetDeltaConnectedBookmarksThunkAction = async (
  payload: {
    filterBookIds?: string[];
    userEmail?: string;
  },
  { getState, dispatch }
) => {
  const getAllQueryOpts = {
    upToVersion: '3',
    filterBookIds: payload.filterBookIds,
    userEmail: payload.userEmail,
  };

  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  const params = new URLSearchParams({
    millisec: getState().ApplicationState.lastPoll.bookmarksServerMillisec,
    upToVersion: '3',
    userEmail: payload.userEmail || '',
  });

  return await tauriFetch(
    ABSOLUTE_PATH + `/api/v14/delta/bookmarks?` + params.toString(),
    {
      method: 'GET',
      mode: 'cors',
      headers: headers,
    }
  ).then(async (response) => {
    if (!response.ok) {
      throw new Error();
    }
    const { result } = await response.json();
    dispatch(
      applicationActions.setBookmarksServerMilliSec(result?.serverMillisec)
    );
    return result.bookmark;
  });
};
